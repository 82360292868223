import React, {useState, useEffect} from 'react';
import {
    Button,
    Typography,
    Box,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    Checkbox,
    FormControlLabel
} from '@mui/material';
import config from '../../config';
import Helper from "../../helpers";
import {bookUserSession, fetchVenueUsers} from "../../Services/UserService.ts"

const baseUrl = config.apiBaseUrl;

const AddUserToSession = ({handleClose, Values}) => {
    const [sessions, setSessions] = useState([]);
    const [selectedSession, setSelectedSession] = useState(Values.sessionId);
    const [selectedUser, setSelectedUser] = useState("");
    const [sessionCost, setSessionCost] = useState(Values.sessionCost);
    const [users, setUserData] = useState([]);
    const [dedutUserToken, setDedutUserToken] = useState(true);

    const updateSelectedSession = (sessionId) => {
        let session = sessions.find(session => session.id === sessionId);
        setSelectedSession(sessionId);
        setSessionCost(session.tokenCost);
    }

    const updateSelectedUser = (userid) => {
        setSelectedUser(userid);
    }


    useEffect(() => {
        // Fetch available sessions
        async function fetchSessions() {
            const token = await Helper.getCookie('wh-jwt');
            let venue = await Helper.getUserVenueId();
            fetch(`${baseUrl}/api/Sessions/available/${venue}?from=${encodeURIComponent(new Date().toISOString())}&to=${encodeURIComponent(new Date(new Date().setDate(new Date().getDate() + 31)).toISOString())}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    credentials: 'include'
                }
            )
                .then(response => response.json())
                .then(data => setSessions(data))
                .catch(error => console.error('Error fetching sessions:', error));

            let usersData = await fetchVenueUsers(venue);

            setUserData(usersData);
        };
        fetchSessions();
    }, []);

    const handleBookSession = async () => {
        let bookedUsersSession = await bookUserSession(selectedUser, selectedSession, dedutUserToken);
        if (bookedUsersSession) {
            handleClose();
        } 
    };
    const label = {inputProps: {'aria-label': 'Checkbox'}};
    return (
        <Box sx={{p: 2, border: '1px solid #ccc', borderRadius: '4px', maxWidth: '400px', margin: 'auto'}}>
            <Typography variant="h6" component="h2">Book a Session</Typography>
            <Typography variant="body" component="body">This session will cost {sessionCost} token</Typography>
            <FormControl fullWidth margin="normal">
                <InputLabel id="session-select-label">Select Session</InputLabel>
                <Select
                    labelId="session-select-label"
                    value={selectedSession}
                    onChange={(e) => updateSelectedSession(e.target.value)}
                    label="Select Session"
                >
                    {sessions.map((session) => (
                        <MenuItem key={session.id} value={session.id}>
                            {`${session.name} - ${new Date(session.startDate).toLocaleString()}`}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <FormControl fullWidth margin="normal">
                <InputLabel id="user-select-label">Select User</InputLabel>
                <Select
                    labelId="user-select-label"
                    value={selectedUser}
                    onChange={(e) => updateSelectedUser(e.target.value)}
                    label="Select User"
                >
                    {users.map((user) => (
                        <MenuItem key={user.userId} value={user.userId}>
                            {`${user.name}`}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
                <FormControlLabel label={"Deduct User Token"} control={
                    <Checkbox {...label}  Label="Deduct User Token" defaultChecked color="secondary" checked={dedutUserToken} onChange={(e) => {
                        setDedutUserToken(e.target.checked)
                    }}/>
                } />
            </FormControl>
            <Button variant="contained" color="primary" onClick={handleBookSession} sx={{mt: 2}}>
                Book Session
            </Button>
            <Button variant="contained" color="secondary" onClick={handleClose} sx={{mt: 2, ml: 2}}>
                Close
            </Button>
        </Box>
    );
};

export default AddUserToSession;
