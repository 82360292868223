import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import config from "../config";
import Helper from "../helpers";
import {Backdrop, CircularProgress} from "@mui/material";
const baseUrl = config.apiBaseUrl;

const Logout = () => {
    const [isLoading, setIsLoading] = React.useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        fetch(`${baseUrl}/api/users/signout`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                setIsLoading(false);
                if (response.ok) {
                    Helper.deleteCookie("wh-jwt");
                    navigate('/login');
                } else {
                    console.error('Failed to log out');
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.error('Error:', error);
            });
    }, [navigate]);

    return (
    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
    </Backdrop>
    );
};

export default Logout;
