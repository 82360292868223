import React, { useEffect, useState } from 'react';
import {
    Typography,
    CircularProgress,
    Backdrop,
    MenuItem,
    Select,
    Paper,
    TableBody, TableContainer, Table, TableHead, TableRow, TableCell, Button
} from '@mui/material';
import Helper from '../helpers';
import config from "../config";

const baseUrl = config.apiBaseUrl;

const GoCardlessUserImport = () => {
    const [loading, setLoading] = useState(true);
    const [gcUsers, setGcUsers] = useState([]);
    const [venueUsers, setVenueUsers] = useState([]);
    const [userMatches, setUserMatches] = useState({});
    const [venueKey, setCurrentVenueKey] = useState("");
    const [venueKeyOptions, setCurrentVenueKeyOptions] = useState([]);

    useEffect(() => {
        const fetchUsers = async () => {
            const token = await Helper.getCookie('wh-jwt');

            const gcResponse = await fetch(`${baseUrl}/api/paymentadmin/importusers/${venueKey}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const gcData = await gcResponse.json();
            setGcUsers(gcData);

            const venueResponse = await fetch(`${baseUrl}/api/users/venueusers/${venueKey}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const venueData = await venueResponse.json();
            setVenueUsers(venueData);

            setLoading(false);
        };

        Helper.getAdminVenues(setCurrentVenueKey, setCurrentVenueKeyOptions)
            .then(fetchUsers);
    }, [venueKey]);

    const handleUserMatchChange = (gcUserId, venueUserId) => {
        setUserMatches({
            ...userMatches,
            [gcUserId]: venueUserId
        });
    };

    const handleSave = async (gcUserId) => {
        const user = gcUsers.find(u => u.id === gcUserId);
        const venueUser = venueUsers.find(u => u.email === userMatches[gcUserId]);

        if (!user || !venueUser) {
            console.error('User or Venue User not found');
            return;
        }

        const payload = {
            ExternalSubsciprtionName: user.subscriptions[0].plan,
            ExternalSubsciprtionId: user.subscriptions[0].id,
            ExternalSubsciprtionIdUserId: gcUserId,
            UserId: venueUser.userId
        };

        try {
            const token = await Helper.getCookie('wh-jwt');
            const response = await fetch(`${baseUrl}/api/subscription/ImportUserWithSubscription/${venueKey}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(payload)
            });

            if (response.ok) {
                console.log('User and subscription saved successfully');
            } else {
                console.error('Failed to save user and subscription');
            }
        } catch (error) {
            console.error('Error saving user and subscription:', error);
        }
    };

    const handleSaveAll = () => {
        // Implement save logic for all users
        console.log('Saving all matches');
    };

    return (
        <div>
            {venueKeyOptions.length > 0 && (
                <select
                    style={{ display: 'none' }}
                    value={venueKey}
                    onChange={(e) => setCurrentVenueKey(e.target.value)}
                >
                    {venueKeyOptions.map((venueKey) => (
                        <option key={venueKey} value={venueKey}>
                            {venueKey}
                        </option>
                    ))}
                </select>
            )}
            {/*<ImportGoCardLessUserStepper currentStep={0} />*/}
            <Typography variant="h4" component="h1" gutterBottom>
                GoCardless User Import
            </Typography>
            {loading ? (
                <Backdrop open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            ) : (
                <>
                    <Button variant="contained" color="primary" onClick={handleSaveAll}>
                        Save All
                    </Button>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Subscription Name</TableCell>
                                    <TableCell>Cost</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Assign to User</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {gcUsers.map(gcUser => (
                                    gcUser.subscriptions.map(subscription => (
                                        <TableRow key={subscription.id}>
                                            <TableCell>{gcUser.email}</TableCell>
                                            <TableCell>{subscription.plan}</TableCell>
                                            <TableCell>{subscription.startDate}</TableCell>
                                            <TableCell>{subscription.amount}</TableCell>
                                            <TableCell>{subscription.status}</TableCell>
                                            <TableCell>
                                                <Select
                                                    value={userMatches[gcUser.id] || ''}
                                                    onChange={(e) => handleUserMatchChange(gcUser.id, e.target.value)}
                                                    displayEmpty
                                                    fullWidth>
                                                    <MenuItem value="" disabled>
                                                        Please select
                                                    </MenuItem>
                                                    {venueUsers.map(venueUser => (
                                                        <MenuItem key={venueUser.id} value={venueUser.email}>
                                                            {venueUser.email}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </TableCell>
                                            <TableCell>
                                                <Button variant="contained" color="secondary" onClick={() => handleSave(gcUser.id)}>
                                                    Save
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            )}
        </div>
    );
};

export default GoCardlessUserImport;
