import {
    Avatar,
    Button,
    Card,
    CardContent,
    Divider,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Grid2";
import AddSession from "./AddSession";
import PopupModelWrapper from "./PopupModelWrapper";
import AddToken from "./AddToken";
import Helper from "../helpers";
import {fetchVenueUsers} from "../Services/UserService.ts";


const ListVenueUsers = (props) => {
    const [users, setUsers] = useState([]);
    const [currentVenueKey, setCurrentVenueKey] = useState("");
    const [venueKeyOptions, setVenueKeyOptions] = useState([]);
    const [selectedUser, setSelectedUser] = useState({});
    const [openAddSessionModal, setOpenAddSessionModal] = useState(false);
    const [openAddTokenModal, setOpenAddTokenModal] = useState(false);

    const handleOpenAddSessionModal = () => setOpenAddSessionModal(true);
    const handleOpenAddTokenModal = (user) => {
            setOpenAddTokenModal(true);
            setSelectedUser(user);
        };
    const handleCloseAddSessionModal = () => setOpenAddSessionModal(false);
    const handleCloseAddTokenModal = (newTokenValue) => {
        selectedUser.tokens = newTokenValue;
        setOpenAddTokenModal(false);
        setSelectedUser({});
    };

    useEffect(() => {
        // const getAdminVenues = async () => {
        //     const token = await window.cookieStore.get('wh-jwt');
        //     let cookie = Helper.parseJwt(token.value);
        //     let admin_venues = cookie.admin_venue;
        //
        //     if (Array.isArray(admin_venues) && admin_venues.length > 0) {
        //         setCurrentVenueKey(admin_venues[0]);
        //         setVenueKeyOptions(admin_venues);
        //     } else if (typeof admin_venues === 'string') {
        //         setCurrentVenueKey(admin_venues);
        //         let venues = [];
        //         venues.push(admin_venues);
        //         setVenueKeyOptions(venues);
        //     }
        // };

        const initialize = async () => {
            await Helper.getAdminVenues(setCurrentVenueKey, setVenueKeyOptions);
            if (currentVenueKey) {
                let userData = await fetchVenueUsers(currentVenueKey);
                setUsers(userData);
            }
        };

        initialize();
    }, [currentVenueKey]);

    if (users.length === 0) {
        return (
            <Card variant="outlined" sx={{height: '100%', flexGrow: 1}}>
                <CardContent>
                    <Typography variant="h3">Venue Users</Typography>
                    <Typography variant="h6" sx={{textAlign: 'center'}}>No users found</Typography>
                    <Typography variant="h4" sx={{textAlign: 'center'}}>User your Invite link to add users</Typography>
                </CardContent>
            </Card>
        )
    }


    return (
        <>
            <Card variant="outlined" sx={{height: '100%', flexGrow: 1}}>
                <CardContent>
                    <Typography variant="h3">Venue Users</Typography>
                    {venueKeyOptions.length > 0 && (
                      <select
                        style={{ display: 'none' }}
                        value={currentVenueKey}
                        onChange={(e) => setCurrentVenueKey(e.target.value)}
                      >
                        {venueKeyOptions.map((venueKey) => (
                          <option key={venueKey} value={venueKey}>
                            {venueKey}
                          </option>
                        ))}
                      </select>
                    )}
                    <Divider variant={"middle"}/>
                    <br/>
                    <List sx={{width: '100%', bgcolor: 'background.paper'}}>
                        {users.map(session => (
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    <Avatar alt={session.name} src="/static/images/avatar/3.jpg"/>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={`${session.name} (${session.userId})`}
                                    secondary={
                                        <Grid container
                                              spacing={2}
                                              columns={12}>
                                            <Grid container size={{xs: 12, sm: 6, lg: 3}}>

                                                <Typography
                                                    component="span"
                                                    variant="body2"
                                                    sx={{color: 'text.primary', display: 'inline'}}
                                                >
                                                    Current Tokens : {session.tokens}
                                                </Typography>
                                            </Grid>
                                            <Grid container size={{xs: 12, sm: 6, lg: 3}}>
                                                <Typography
                                                    component="span"
                                                    variant="body2"
                                                    sx={{color: 'text.primary', display: 'inline'}}>
                                                    Booked Sessions : {session.bookedSessions}
                                                </Typography>
                                            </Grid>
                                            <Grid container size={{xs: 12, sm: 6, lg: 3}}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    size={"small"}
                                                    //disabled={session.availableSeats === 0}
                                                    onClick={() => handleOpenAddTokenModal(session)}
                                                >
                                                    Add Tokens
                                                </Button>
                                            </Grid>
                                            <Grid container size={{xs: 12, sm: 6, lg: 3}}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    size={"small"}
                                                    //disabled={session.availableSeats === 0}
                                                    onClick={() => handleOpenAddSessionModal()}
                                                >
                                                    Assign Session
                                                </Button>
                                            </Grid>
                                            <Grid container size={{xs: 12, sm: 6, lg: 3}}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    size={"small"}
                                                    //disabled={session.availableSeats === 0}
                                                    onClick={() => handleOpenAddSessionModal()}
                                                >
                                                    Assign Subscription Key
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    }
                                />
                            </ListItem>
                        ))}

                    </List>
                </CardContent>
            </Card>
            <PopupModelWrapper isOpen={openAddSessionModal} handleClose={handleCloseAddSessionModal}
                               ModelContent={AddSession} Values={{venue : {key : currentVenueKey}}}/>
            <PopupModelWrapper isOpen={openAddTokenModal} handleClose={handleCloseAddTokenModal}
                               ModelContent={AddToken} Values={{user : selectedUser, venue : {key : currentVenueKey}}}/>
        </>
    )
        ;
}

export default ListVenueUsers;
