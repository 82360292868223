import React, {useState, useEffect} from 'react';
import {Button, Typography, Box, MenuItem, Select, FormControl, InputLabel} from '@mui/material';
import config from '../config';
import Helper from "../helpers";

const baseUrl = config.apiBaseUrl;

const BookSession = ({handleClose, Values}) => {
    const [sessions, setSessions] = useState([]);
    const [selectedSession, setSelectedSession] = useState(Values.sessionId);
    const [sessionCost, setSessionCost] = useState(Values.sessionCost);

    const updateSelectedSession = (sessionId) => {
        let session = sessions.find(session => session.id === sessionId);
        setSelectedSession(sessionId);
        setSessionCost(session.tokenCost);
    }


    useEffect( () => {
        // Fetch available sessions
        async function fetchSessions() {
            const token = await Helper.getCookie('wh-jwt');
            let venue = await Helper.getUserVenueId();
            fetch(`${baseUrl}/api/Sessions/available/${venue}?from=${encodeURIComponent(new Date().toISOString())}&to=${encodeURIComponent(new Date(new Date().setDate(new Date().getDate() + 31)).toISOString())}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    credentials: 'include'
                }
            )
                .then(response => response.json())
                .then(data => setSessions(data))
                .catch(error => console.error('Error fetching sessions:', error));
        };
        fetchSessions();
    }, []);

    const handleBookSession = async () => {
        const token = await Helper.getCookie('wh-jwt');
        fetch(`${baseUrl}/api/Sessions/book/${selectedSession}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            credentials: 'include'
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    alert('Failed to book session');
                }
            })
            .then(data => {
                if (data) {
                    alert('Session booked successfully');
                    handleClose();
                    window.location.reload();
                } else {
                    alert('Failed to book session');
                }
            })
            .catch(error => {
                console.error('Error booking session:', error);
                alert('Failed to book session');
            });
    };

    return (
        <Box sx={{p: 2, border: '1px solid #ccc', borderRadius: '4px', maxWidth: '400px', margin: 'auto'}}>
            <Typography variant="h6" component="h2">Book a Session</Typography>
            <Typography variant="body" component="body">This session will cost {sessionCost} token</Typography>
            <FormControl fullWidth margin="normal">
                <InputLabel id="session-select-label">Select Session</InputLabel>
                <Select
                    labelId="session-select-label"
                    value={selectedSession}
                    onChange={(e) => updateSelectedSession(e.target.value)}
                    label="Select Session"
                >
                    {sessions.map((session) => (
                        <MenuItem key={session.id} value={session.id}>
                            {`${session.name} - ${new Date(session.startDate).toLocaleString()}`}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <Button variant="contained" color="primary" onClick={handleBookSession} sx={{mt: 2}}>
                Book Session
            </Button>
            <Button variant="contained" color="secondary" onClick={handleClose} sx={{mt: 2, ml: 2}}>
                Close
            </Button>
        </Box>
    );
};

export default BookSession;
