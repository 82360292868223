import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Box, Button, Collapse, CssBaseline } from '@mui/material';
import { Link } from 'react-router-dom';
import { Home, AccountCircle, ExitToApp, Menu as MenuIcon } from '@mui/icons-material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import TokenCount from "./ToeknCount";
import './NavMenu.css';
import Helper from "../helpers";

const drawerWidth = 240;

const NavMenu = () => {
    const [collapsed, setCollapsed] = useState(true);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const theme = useTheme();
    const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        const checkAdmin = async () => {
            const admin = await Helper.userIsAdmin();
            setIsAdmin(admin);
        };
        
        // Function to check if the user is logged in
        const checkLoginStatus = async () => {
            const token = await Helper.getCookie('wh-jwt');
            setIsLoggedIn(!!token);
        };

        // Check login status on component mount
        checkAdmin();
        checkLoginStatus();

        // Recheck login status when isLoggedIn state changes
        const interval = setInterval(checkLoginStatus, 5000); // Recheck every 5 seconds
        return () => clearInterval(interval); // Cleanup interval on component unmount
    }, [isLoggedIn]);

    const toggleNavbar = () => {
        setCollapsed(!collapsed);
    };

    return (
        <>
            <CssBaseline />
            <AppBar
                position="static"
                color="default"
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                    transition: theme.transitions.create(['width', 'margin'], {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen,
                    }),
                }}
            >
                <Toolbar>
                    <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                        <Typography variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
                            WorldsHighStreet
                        </Typography>
                    </Box>
                    <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleNavbar}>
                        <MenuIcon />
                    </IconButton>
                    <Collapse in={!collapsed} timeout="auto" unmountOnExit>
                        <Box sx={{ display: 'flex', flexDirection: isMobileOrTablet ? 'column' : 'row' }}>
                            <Button component={Link} to="/dashboard" color="inherit" startIcon={<Home />}>
                                Home
                            </Button>
                            <Button component={Link} to="/logout" color="inherit" startIcon={<ExitToApp />}>
                                Logout
                            </Button>
                            {isLoggedIn && (
                                <>
                                    <Button component={Link} to="/dashboard/sessions?selected=mysessions" color="inherit" startIcon={<AccountCircle />}>
                                        Sessions
                                    </Button>
                                    {isAdmin && (
                                    <Button component={Link} to="/dashboard/adminSession" color="inherit" startIcon={<AccountCircle />}>
                                        Admin Sessions
                                    </Button>
                                    )}
                                </>
                            )}
                        </Box>
                    </Collapse>
                    <TokenCount isLoggedIn={isLoggedIn} />
                </Toolbar>
            </AppBar>
        </>
    );
};

export default NavMenu;