import config from '../config';
import Helper from "../helpers";

const apiBaseUrl = config.apiBaseUrl;

export const getUsersForVenue = async (venueKey: string) => {
    try {
        const response = await fetch(`${apiBaseUrl}/api/venue/${venueKey}/users`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching users for venue:', error);
        throw error;
    }
}

export const fetchVenueUsers = async (venueKey: string) => {
    const token = await Helper.getCookie('wh-jwt');
    try {
        const response = await fetch(`${apiBaseUrl}/api/users/venueusers/${venueKey}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            credentials: 'include'
        });
        if (!response.ok) {
            console.error('Failed to fetch users', response.statusText);
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return data;
        
    } catch (error) {
        console.error('Error fetching users for venue:', error);
        throw error;
    }
};

export const bookUserSession = async (userId: string, sessionId: string, deductUserToken: boolean = true) => {
    const token = await Helper.getCookie('wh-jwt');
    try {
        const response = await fetch(`${apiBaseUrl}/api/Sessions/book/${sessionId}?userid=${userId}&deuctuser=${deductUserToken}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            credentials: 'include'
        });

        if (!response.ok) {
            alert('Failed to book session');
            return false
        } 
        let data = await response.json();
        if (data) {
            alert('Session booked successfully');
            return true;
        } else {
            alert('Failed to book session');
            return false;
        }
    } catch (error) {
        console.error('Error booking session:', error);
        alert('Failed to book session');
        return false;
    }
}