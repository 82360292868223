import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    CssBaseline,
    Modal, Tab, Tabs,
    Typography
} from '@mui/material';
import { format, parseISO } from 'date-fns';
import { useLocation } from 'react-router-dom';
import Helper from "../helpers";
import config from "../config";
import SessionView from "../components/SessionView";

const baseUrl = config.apiBaseUrl;

const SessionList = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const selectedQuery = queryParams.get('selected');

    const [sessionData, setSessionData] = useState([]);
    const [groupedSessionData, setGroupedSessionData] = useState([]);
    const [usersList, setUsersList] = useState([]);

    const [selectedDate, setSelectedDate] = useState(Object.keys(groupedSessionData)[0]);
    const [showMySessions, setShowMySessions] = useState(selectedQuery === 'mysessions');
    const [open, setOpen] = useState(false);
    const [modelaTitle, setTitle] = useState(false);
    const [showWaitList, setWaitlist] = useState(false);
    const [isBooked, setIsBooked] = useState(false);
    //const [venueKeyOptions, setVenueKeyOptions] = useState([]);
    const [currentVenueKey, setCurrentVenueKey] = useState("");

    const [value, setValue] = useState(showMySessions ? 'mySessions' : selectedDate);

    const filteredSessions = showMySessions
        ? sessionData.filter(session => usersList.includes(session.sessionId))
        : selectedDate ? groupedSessionData[selectedDate] : groupedSessionData[Object.keys(groupedSessionData)[0]];

    const handleOpen = (event) => {
        setOpen(true);
        console.log('Event selected:', event);
        setTitle(event.title);
        if (event.userType === 'Booked') {
            setIsBooked(true);
        } else {
            setIsBooked(false);
        }
        if (event.userType === 'Waiting List') {
            setWaitlist(true);
        } else {
            setWaitlist(false);
        }
    }
    const handleChange = (event, newValue) => {
        if (newValue === 'mySessions') {
            setShowMySessions(true);
        } else {
            setSelectedDate(newValue);
            setShowMySessions(false);
        }
        setValue(newValue);
    };
    const handleClose = () => setOpen(false);

    useEffect(() => {
        const fetchUserSessions = async (cvk) => {
            try {
                const token = await Helper.getCookie('wh-jwt');
                const response = await fetch(`${baseUrl}/api/sessions/user/${cvk}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    credentials: 'include'
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                const userSessionIds = data.userSessions.map(session => session.bookableSessionId);
                setUsersList(userSessionIds);
                const formattedEvents = data.availableSessions.map(session => {
                    const start = new Date(session.startDate);
                    return {
                        sessionId: session.id,
                        title: session.name,
                        startDate: session.startDate,
                        start,
                        end: new Date(start.getTime() + session.numberOfMinuets * 60000),
                        sessionBookText: userSessionIds.includes(session.id) ? 'Booked' : 'Book',
                        availableSeats: session.availableSeats,
                        seatsTaken: session.seatsTaken,
                        numberOfMinuets: session.numberOfMinuets,
                        sessionCost: session.tokenCost
                    };
                });

                setSessionData(formattedEvents);

                // Group sessions by date
                const groupedSessions = formattedEvents
                    .sort((a, b) => new Date(a.startDate) - new Date(b.startDate))
                    .reduce((acc, session) => {
                        const date = session.startDate.split('T')[0];
                        if (!acc[date]) {
                            acc[date] = [];
                        }
                        acc[date].push(session);
                        return acc;
                    }, {});
                setGroupedSessionData(groupedSessions);
                setSelectedDate(Object.keys(groupedSessions)[0]);
            } catch (error) {
                console.error('Error fetching user sessions', error);
            }
        }

        const initialize = async () => {
            let venue = await Helper.getUserVenueId();
            setCurrentVenueKey(venue);
            await fetchUserSessions(venue);
        };

        initialize();
    }, [currentVenueKey]);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    if (!groupedSessionData || Object.keys(groupedSessionData).length <= 0) {
        return (
            <Box sx={{ p: 2 }}>
                <Typography variant="h4" gutterBottom>No sessions</Typography>
            </Box>
        )
    }

    return (
        <>
            <CssBaseline />
            <Box sx={{ p: 2 }}>
                <Typography variant="h4" gutterBottom>
                    Session List
                </Typography>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                    aria-label="session tabs"
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        borderBottom: 1,
                        mb: 2,
                        maxWidth: { xs: 380, sm: 500, md: 700, lg: 950, xl: 1100 },
                        '& .MuiTabs-scrollButtons': {
                            '&.Mui-disabled': { opacity: 0.3 },
                        },
                        margin:'5px auto'
                    }}
                >
                    <Tab
                        label="My Sessions"
                        value="mySessions"
                        sx={{
                            width: {xs: 70, sm: 100},
                            minWidth: {xs: 25, sm: 100}, 
                            color: '#49e197',
                            border:'1px solid #627069',
                            borderTopLeftRadius: '8px', // Adjust the value as needed
                            borderTopRightRadius: '8px'
                        }}
                    />
                    {Object.keys(groupedSessionData).map(date => (
                        <Tab
                            key={date}
                            label={
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: value === date ? '#e7fae7' : '#49e197' }}>
                                    <Typography variant="body1">{format(parseISO(date), 'MMM dd')}</Typography>
                                    <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>{format(parseISO(date), 'eeee')}</Typography>
                                </Box>
                            }
                            value={date}
                            sx={{ minWidth: { xs: 'auto', sm: 100,
                                    border:'1px solid #627069',
                                    borderTopLeftRadius: '8px', // Adjust the value as needed
                                    borderTopRightRadius: '8px', padding:'2px 2px', margin : '0px 2px' } }}
                        />
                    ))}
                </Tabs>
                {filteredSessions.length <= 0 ? (
                    <Card
                        sx={{
                            mb: 2,
                            borderRadius: 3, // Rounded corners
                            boxShadow: 3, // Subtle shadow
                            overflow: "hidden", // Hide image overflow for clean edges
                        }}
                    >
                        <CardContent>
                            <Typography variant="h6" gutterBottom>No sessions available</Typography>
                            <Typography variant="h5" gutterBottom>Select a Date to find a session</Typography>
                        </CardContent>
                    </Card>
                ) : (
                    filteredSessions.map((session) => (
                        <SessionView session={session} handleOpen={handleOpen} />
                    ))
                )}
            </Box>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {modelaTitle}
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {isBooked && <Typography variant="body1">You have already booked this session</Typography>}
                        {showWaitList && <Typography variant="body1">Session is full, join the waiting list <Button
                            variant="contained" color="secondary">Join Waiting List</Button></Typography>}
                        {!isBooked && !showWaitList &&
                            <Typography variant="body1">There are slots available for this session <Button
                                variant="contained" color="primary">Book Now</Button></Typography>}
                    </Typography>
                </Box>
            </Modal>
        </>
    );
};

export default SessionList;
