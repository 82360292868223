const Helper = {
    parseJwt: parseJwt,
    getAdminVenues: getAdminVenues,
    userIsAdmin: userIsAdmin,
    getUserId: getUserId,
    getUserVenueId: getUserVenueId,
    setCookie: setCookie,
    getCookie: getCookie,
    deleteCookie : deleteCookie
};

function parseJwt(token) {
    try {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    } catch (e) {
        console.error('Invalid token', e);
        return null;
    }
}

async function getAdminVenues(setCurrentVenueKey, setVenueKeyOptions) {
    const token = await getCookie('wh-jwt');
    let cookie = Helper.parseJwt(token);
    let admin_venues = cookie.admin_venue;

    if (Array.isArray(admin_venues) && admin_venues.length > 0) {
        setCurrentVenueKey(admin_venues[0]);
        setVenueKeyOptions(admin_venues);
    } else if (typeof admin_venues === 'string') {
        setCurrentVenueKey(admin_venues);
        let venues = [];
        venues.push(admin_venues);
        setVenueKeyOptions(venues);
    }
};

async function userIsAdmin() {
    const token = await getCookie('wh-jwt');
    let cookie = Helper.parseJwt(token);
    if(!cookie || !cookie.admin_venue) {
        return false;
    }
    let admin_venues = cookie.admin_venue;

    return (Array.isArray(admin_venues) && admin_venues.length > 0) || typeof admin_venues === 'string';

};

async function getUserId() {
    const token = await getCookie('wh-jwt');
    let cookie = Helper.parseJwt(token);
    if(!cookie || !cookie.sub) {
        return false;
    }
    return cookie.sub;
}

async function getUserVenueId() {
    const token = await getCookie('wh-jwt');
    let cookie = Helper.parseJwt(token);
    if(!cookie || !cookie.venue) {
        return false;
    }
    return cookie.venue;
}

function setCookie(key, value, days) {
    let expires = "";
    let date = new Date();
    if (days) {
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    const cookieString = key + "=" + (value || "") + expires + "; path=/; SameSite=None; Secure";

    if (window.cookieStore) {
        window.cookieStore.set({
            name: key,
            value: value,
            expires: date,
            sameSite: 'lax',
            secure: true
        });
    } else {
        document.cookie = cookieString;
    }
}

async function getCookie(name) {
    if (window.cookieStore) {
        const cookie = await window.cookieStore.get(name);
        return cookie ? cookie.value : null;
    } else {
        const nameEQ = name + "=";
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }
}

async function deleteCookie(name) {
    if (window.cookieStore) {
        await window.cookieStore.delete(name);
    } else {
        document.cookie = name + '=; Max-Age=-99999999; path=/; SameSite=None; Secure';
    }
}

export default Helper;
