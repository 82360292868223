import React, {useState, useEffect} from 'react';
import {TextField, Button, Alert, Box, Typography, List, ListItem} from '@mui/material';
import config from '../config';
import Helper from "../helpers";

const baseUrl = config.apiBaseUrl;

const ManageSession = (props) => {
    const {handleClose, Values} = props;
    const [sessionName, setSessionName] = useState('');
    const [users, setUsers] = useState([]);
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        // Fetch session details
        fetch(`${baseUrl}/api/Sessions/${Values.sessionId}`)
            .then(response => response.json())
            .then(data => {
                setSessionName(data.name);
                setUsers(data.users);
            })
            .catch(error => {
                console.error('Error fetching session details:', error);
                setError('Failed to fetch session details');
            });
    }, [Values.sessionId]);

    const handleUpdateSession = async () => {
        setMessage('');
        setError('');

        const updatedSession = {
            name: sessionName,
        };
        const token = await Helper.getCookie('wh-jwt');
        fetch(`${baseUrl}/api/Sessions/${Values.sessionId}`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify(updatedSession),
        })
            .then(response => {
                if (response.ok) {
                    setMessage('Session updated successfully');
                } else {
                    throw new Error('Failed to update session');
                }
            })
            .catch(error => {
                console.error('Error updating session:', error);
                setError('Failed to update session');
            });
    };

    const handleCancelSession = async () => {
        setMessage('');
        setError('');
        const token = await Helper.getCookie('wh-jwt');
        fetch(`${baseUrl}/api/Sessions/admin/delete/${Values.sessionId}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            credentials: 'include',
        })
            .then(response => {
                if (response.ok) {
                    setMessage('Session removed successfully');
                    handleClose();
                    window.location.reload();
                } else {
                    throw new Error('Failed to removed session');
                }
            })
            .catch(error => {
                console.error('Error removing session:', error);
                setError('Failed to removed session');
            });
    };

    return (
        <Box sx={{p: 2, border: '1px solid #ccc', borderRadius: '4px'}}>
            <Typography variant="h6">Manage Session</Typography>
            <TextField
                label="Session Name"
                value={sessionName}
                onChange={(e) => setSessionName(e.target.value)}
                fullWidth
                margin="normal"
            />
            <Button variant="contained" color="primary" onClick={handleUpdateSession} sx={{mt: 2}}>
                Update Session
            </Button>
            <Button variant="contained" color="secondary" onClick={handleCancelSession} sx={{mt: 2, ml: 2}}>
                Cancel Session
            </Button>
            <Typography variant="h6" sx={{mt: 4}}>Users in Session</Typography>
            <List>
                {users.map((user, index) => (
                    <ListItem key={index}>
                        <Typography>{user.name}</Typography>
                    </ListItem>
                ))}
            </List>
            {message && <Alert severity="success" sx={{mt: 2}}>{message}</Alert>}
            {error && <Alert severity="error" sx={{mt: 2}}>{error}</Alert>}
        </Box>
    );
};

export default ManageSession;
